<template>
  <div>
    <div v-if="$store.getters.isAdmin" class="admin-list">
      <h4 class="list-title">{{ $lang.app.help }}</h4>
      <div class="admin-navbar align-right">
        <router-link to="/admin/help/create" class="action-button">{{ $lang.app.create }}</router-link>
      </div>
      <div class="admin-table">
        <table>
          <thead>
          <tr>
            <th class="key">{{ $lang.app.category }}</th>
            <th>{{ $lang.app.content_type }}</th>
            <th>{{ $lang.app.heading }}</th>
            <th>{{ $lang.app.content }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="helpItem in help" :key="helpItem.id">
            <td class="key">{{ typeLabel(helpItem.organizationType) }}</td>
            <td>{{ helper.types.find(type => type.value === helpItem.type).label }}</td>
            <td>{{ $lang.$translate({ru: helpItem.titleRu, kg: helpItem.titleKg}) }}</td>
            <td>
              <a href @click.prevent="openFile(helpItem.filename)">
                {{ helpItem.filename }}
              </a>
              <a v-if="helpItem.type === 'URL'" :href="helpItem.url" target="_blank">
                {{ helpItem.url }}
              </a>
            </td>
            <td>
              <div class="action-buttons">
                <router-link :to="`/admin/help/${helpItem.id}`"
                             class="action-button" :title="$lang.app.view"><i class="bi-info-square"></i></router-link>
                <router-link :to="`/admin/help/${helpItem.id}/edit`"
                             class="action-button" :title="$lang.app.edit"><i class="bi-pencil-square"></i></router-link>
                <button v-if="$store.getters.isAdmin" class="action-button" :title="$lang.app.delete" @click="deleteRequest(helpItem.id)"><i class="bi-x-square"></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <DeleteModal v-if="deleteId"
                   @close="deleteId = null"
                   @delete="deleteResource"
      />
    </div>

    <div v-if="$store.getters.isEmployee">
      <div class="admin-list">
        <h4 class="list-title">{{ $lang.app.help }}</h4>
        <div class="help-video">
          <div v-for="video in videos" :key="video.id" class="video-item">
            <div class="video-card">
              <iframe type="text/html" width="400" height="225"
                      :src="getLink(video.url)"/>
              <p class="video-title">{{ $lang.$translate({ru: video.titleRu, kg: video.titleKg}) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="admin-list help-docs-block">
        <h4 class="list-title docs-title">{{ $lang.app.documents }}</h4>
        <div class="help-docs">
          <div v-for="(doc, index) in docs" :key="doc.id" class="doc-item">
            <a href @click.prevent="openFile(doc.filename)" class="doc-link">
              {{ index + 1 }}) {{ $lang.$translate({ru: doc.titleRu, kg: doc.titleKg}) }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeleteModal from '../../../components/admin/DeleteModal'
import fileUploadMixin from "../../../mixins/fileUploadMixin";
export default {
  components: {
    DeleteModal
  },
  mixins: [fileUploadMixin],
  data() {
    return {
      helper: {
        types: [
          {
            value: 'DOC',
            label: this.$lang.app.document,
          },
          {
            value: 'URL',
            label: this.$lang.app.link,
          }
        ]
      },
      help: [],
      deleteId: null,
    }
  },
  computed: {
    videos() {
      return this.help.filter(item => item.type === 'URL' && this.isYoutubeVideo(item.url))
    },
    docs() {
      return this.help.filter(item => item.type === 'DOC')
    }
  },
  watch: {
    '$route'() {
      this.fetchResources()
    }
  },
  methods: {
    parsedUrl(url) {
      return new URL(url)
    },
    isYoutubeVideo(url) {
      return this.parsedUrl(url).searchParams.has('v')
    },
    getLink(url) {
      return `https://www.youtube.com/embed/${this.parsedUrl(url).searchParams.get('v')}?origin=${location.origin}&fs=0&loop=1`
    },
    typeLabel(value) {
      switch (value) {
        case 'SCHOOL': return this.$lang.app.school
        case 'PRESCHOOL': return this.$lang.app.preschool
      }
    },
    fetchResources() {
      if(this.$store.getters.isAdmin) {
        this.$axios.get(`/manual/list`).then(({data}) => {
          this.help = data
        })
      }
      if(this.$store.getters.isEmployee) {
        this.$axios.get(`/manual/list-by-organization-type?organizationType=${this.getOrganizationType()}`)
            .then(({data}) => {
              this.help = data
            })
      }
    },
    getOrganizationType() {
      if(this.$store.getters.isSchool) {
        return 'SCHOOL'
      }
      if(this.$store.getters.isPreschool) {
        return 'PRESCHOOL'
      }
      return 'SCHOOL'
    },
    openFile(filename) {
      this.downloadFile(filename).then(data => {
        let extension = filename.split('.').pop().toLowerCase();
        if(['png', 'jpg', 'jpeg'].includes(extension)) {
          let file = URL.createObjectURL(new Blob([data],{type: `image/${extension}`}));
          window.open(file, '_blank');
        }
        if(extension === 'pdf') {
          let file = URL.createObjectURL(new Blob([data],{type: 'application/pdf'}));
          window.open(file, '_blank');
        }
      })
    },
    deleteRequest(id) {
      this.deleteId = id
    },
    deleteResource() {
      this.$axios.delete(`/manual/delete/${this.deleteId}`).then(() => {
        this.deleteId = null
        this.fetchResources()
        this.$snotify.success(this.$lang.app.resource_deleted_successfully);
      })
    }
  },
  mounted() {
    this.fetchResources()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
.help-video {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.video-item {
  width: 33.33%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.video-card {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid lightgray;
}
.video-title {
  text-align: center;
}
.help-docs-block {
  padding-left: 40px;
}
.docs-title {
  text-transform: capitalize !important;
}
.doc-item {
  font-size: 20px;
  margin-bottom: 5px;
}
.doc-link {
  text-decoration: none;
}
</style>